.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  &-label {
    font-size: 30px;
    font-weight: 300;
    color: rgb(var(--app-text-rgb));
  }

  &-divider {
    width: 70%;
    border-bottom: .5px solid lightgray;
  }
}