.default-button {
  min-width: 150px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(var(--app-primary-rgb));
  color: rgb(var(--app-primary-rgb));
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: .2s;

  &:hover {
    color: rgb(var(--app-text-rgb));
    border-color: rgb(var(--app-text-rgb));
  }
}

.navbar-menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(var(--app-primary-rgb));
  color: rgb(var(--app-primary-rgb));
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: .2s;
  font-size: 15px;
  padding: 10px 16px;

  &:hover {
    color: rgb(var(--app-text-rgb));
    border-color: rgb(var(--app-text-rgb));
  }
}