.mosaic {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  grid-gap: 20px;

  .wide {
    grid-column: span 2;
  }

  .tall {
    grid-row: span 2;
  }

  .big {
    grid-row: span 2;
    grid-column: span 2;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-picture {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;

    &-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    &:hover {
      transform: scale(1.03, 1.03);
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadein .3s;

    &-content {
      border: 15px solid white;
      border-radius: 5px;
      position: relative;
      max-width: 90%;
      max-height: 80%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &-picture {
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    &-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      font-size: 25px;
      border: 2px solid rgb(var(--app-background-layout-rgb));
      color: rgb(var(--app-background-layout-rgb));
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: white;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 680px) {

  .mosaic {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: unset;
  }
}

@media only screen and (max-width: 500px) {

  .mosaic {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
}