.activities {
  display: flex;
  flex-direction: column;
  row-gap: 150px;
  justify-content: center;
  padding-bottom: 50px;

  &__header {
    width: 100%;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &-title {
      width: 100%;
      max-width: 800px;
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 45px;
      font-weight: 300;
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 100px;

    &-button {
      max-width: 150px;
    }

    &-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 50px;
    }

    &-presentation {
      width: 100%;
      height: auto;
      font-weight: 300;
      font-size: 20px;
      text-align: center;
    }

    &-location {
      flex-grow: 3;
      height: 500px;
      display: flex;
      column-gap: 20px;
      padding: 5px;

      &-map {
        height: 100%;
        flex-grow: 2;
      }

      &-around {
        height: 100%;
        padding: 5px;
        flex-grow: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
      }
    }
  }
}