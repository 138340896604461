.header {
  width: 100%;
  height: var(--app-header-height-expanded);
  background-color: rgb(var(--app-background-header-rgb));
  position: fixed;
  transition: 0.3s;
  top: 0;
  z-index: 1;
}

.header-shrink {
  height: var(--app-header-height-collapsed);
}

.close {
  transform: rotate(180deg);
}


@media only screen and (max-width: 860px) {

  .header {
    height: var(--app-header-height-collapsed);
  }
}