.navbar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 50px;
  z-index: 1;
}

.navbar-logo {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;

  a {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.navbar-logo-picture {
  display: block;
  height: 50%;
  max-height: 120px;
  min-height: 40px;
  transition: .3s;

  &:hover {
    filter: brightness(0.75);
  }
}

.navbar-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 25px;
  padding-right: 1.5rem;

  &.active {
    top: var(--app-header-height);
    left: 0;
    overflow: auto;
    height: 100vh;
    clip-path: inset(0px -15px 0px 0px);
  }
}

.navbar-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  column-gap: 10px;
  transition: .3s;

  &:hover {
    color: rgb(var(--app-primary-rgb));
  }
}

.hamburger {
  display: none;
  background: transparent;
  border: none;
  transition: .3s;
  z-index: 2;
  margin-right: 1.5rem;

  &-segment {
    display: block;
    width: 25px;
    height: 1px;
    margin: 7px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
  }
}


@media only screen and (max-width: 860px) {

  .navbar {
    flex-direction: row;
  }

  .navbar-menu {
    width: 100%;
    top: var(--app-header-height-collapsed);
    height: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    position: fixed;
    display: flex;
    row-gap: 20px;
    justify-content: flex-start;
    flex-direction: column;
    background: rgb(var(--app-background-layout-rgb));
    transition: 0.5s;
    color: white;
    overflow: hidden;

    &.active {
      top: var(--app-header-height-collapsed);
      left: 0;
      overflow: auto;
      height: 100vh;
      padding-top: 50px;
      clip-path: inset(0px -15px 0px 0px);
    }
  }

  .navbar-menu-item {
    width: 100%;
    height: auto;
    text-align: start;
    font-size: 20px;
    justify-content: space-between;
    padding: 0 3em;
  }

  .hamburger {
    display: block;
    cursor: pointer;

    &:hover {
      .hamburger-segment {
        background-color: rgb(var(--app-primary-rgb));
      }
    }

    &.active &-segment:nth-child(2) {
      background-color: rgb(var(--app-primary-rgb));
      opacity: 0;
    }

    &.active &-segment:nth-child(1) {
      background-color: rgb(var(--app-primary-rgb));
      -webkit-transform: translateY(8px) rotate(45deg);
      transform: translateY(8px) rotate(45deg);
    }

    &.active &-segment:nth-child(3) {
      background-color: rgb(var(--app-primary-rgb));
      -webkit-transform: translateY(-8px) rotate(-45deg);
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

@media only screen and (max-width: 860px) {
  .navbar-menu-item {
    justify-content: center;
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .navbar-menu {
    width: 100%;
  }
}

@media only screen and (max-width: 350px) {
  .navbar {
    column-gap: 25px;
    padding-left: .5em;
  }

  .navbar-logo-picture {
    min-height: 40px;
  }
}