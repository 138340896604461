.layout {
  padding: var(--app-header-height-collapsed) 3em calc(var(--app-footer-height) + 50px) 3em;
  transition: .2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgb(0, 0, 0, .5) 0, rgb(var(--app-background-layout-rgb)) 25%, rgb(var(--app-background-layout-rgb)) 100%),
    url(./../../../public/assets/pictures/dark_kitchen.png);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 860px) {

  .layout {
    background-image: linear-gradient(180deg, rgb(0, 0, 0, .5) 0, rgb(var(--app-background-layout-rgb)) 25%, rgb(var(--app-background-layout-rgb)) 100%);
  }
}