.contact-button__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: rgb(var(--app-background-layout-rgb), .7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.3s;
  padding: 0 20px;

  &-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
    max-width: 600px;
    background-color: #292929;
    border-radius: 5px;
    padding: 20px 40px;
    border: 1px solid white;

    &-title {
      font-weight: 300;
    }

    &-paragraph {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
    }

    &-contact {
      display: flex;
      flex-direction: column;
      padding: 10px 0 20px;

      &-label {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}