.footer {
  width: 100%;
  height: var(--app-footer-height);
  position: fixed;
  bottom: 0;
  background-color: rgb(var(--app-background-layout-rgb));
  color: rgb(var(--app-text-rgb));
  text-align: center;
  z-index: 1;
}

.footer-copyright {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  column-gap: 10px;
}