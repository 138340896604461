.about {
  display: flex;
  flex-direction: column;
  row-gap: 150px;
  justify-content: center;
  padding-bottom: 50px;

  &__header {
    width: 100%;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &-title {
      width: 100%;
      max-width: 800px;
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 45px;
      font-weight: 300;
    }
  }

  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 100px;

    &-button {
      max-width: 150px;
    }

    &-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 50px;
    }

    &-presentation {
      width: 100%;
      height: auto;
      font-weight: 300;
      font-size: 20px;
      text-align: center;
    }

    &-location {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 30px;

      &-label {
        display: flex;
        align-items: center;
        column-gap: 20px;
      }

      &-icon {
        min-width: 50px;
        min-height: 20px;
        color: rgb(var(--app-primary-rgb));
      }
    }

    &-caracteristics {
      display: flex;
      justify-content: space-evenly;
      column-gap: 100px;

      &-panel {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(0, 0, 0, .2);
        row-gap: 25px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 25px;

        &-title {
          font-size: 20px;
          font-weight: 300;
        }

        &-divider {
          width: 100%;
          border-bottom: .25px solid lightgray;
          margin-bottom: 20px;
        }
      }

      &-element {
        width: 100%;
        display: flex;
        column-gap: 30px;
        align-items: flex-start;

        &>ul {
          padding: 0;
          margin: -5px 0 0;

          &>li {
            margin-bottom: 5px;
            list-style: none;
          }
        }

        &-icon {
          min-width: 50px;
          min-height: 20px;
          color: rgb(var(--app-primary-rgb));
        }
      }
    }
  }
}

@media only screen and (max-width: 860px) {

  .about__body-caracteristics {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    row-gap: 25px;
  }
}