.home {
  &__header {
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 60px;
    overflow: hidden;

    &-title {
      width: 100%;
      max-width: 800px;
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 45px;
      font-weight: 300;
    }

    &-navigation {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 80px;
    }
  }

  &__galery {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 50px;
  }
}

@media only screen and (max-width: 860px) {

  .home__header-navigation {
    flex-direction: column;
    row-gap: 30px;
  }
}