.map {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;

  &-container {
    width: 100% !important;
    height: 100% !important
  }
}