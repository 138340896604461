@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --app-background-layout-rgb: 34, 34, 34;
  --app-background-header-rgb: 15, 15, 15;
  --app-background-rgb: 60, 60, 60;
  --app-primary-rgb: 0, 214, 180;
  --app-text-rgb: 255, 255, 255;
  --app-header-height-expanded: 110px;
  --app-header-height-collapsed: 80px;
  --app-footer-height: 40px;
}

html,
body {
  height: 100vh;
  background-color: rgb(var(--app-background-layout-rgb));
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(var(--app-text-rgb));
}

p {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: rgb(var(--app-text-rgb));
}

.page {
  width: 100%;
  height: 100%;
  max-width: 1100px;
}